<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>代理商管理</p>
			<i>/</i>
			<p @click="jumpPage">代理商列表</p>
			<i>/</i>
			<p class="active">代理商编辑</p>
		</nav>
	
		<div class="page_edit">
			
			<h4 class="h4">基础信息</h4>
			
			<div class="page_edit_form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="" prop="name">
						<p class="form_label_p">代理商名称</p>
						<el-input class="input_long" v-model="ruleForm.name" placeholder="请输入代理商名称(文本20)"></el-input>
					</el-form-item>
					<el-form-item label="" prop="contactor">
						<p class="form_label_p">联系人姓名</p>
						<el-input class="input_long" v-model="ruleForm.contactor" placeholder="请输入联系人姓名">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="mobile">
						<p class="form_label_p">手机号</p>
						<el-input class="input_long" v-model="ruleForm.mobile" maxlength="11" placeholder="请输入手机号">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="agent_role_id">
						<p class="form_label_p">代理商角色</p>
						<el-select class="input_long" v-model="ruleForm.agent_role_id" filterable placeholder="请选择">
							<el-option v-for="item in roleLidt" :key="item.id"
								:label="item.role_name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="" prop="sort">
						<p class="form_label_p">排序</p>
						<el-input class="input_long" v-model="ruleForm.sort" placeholder="请输入排序(整数越小越靠前)">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="useful_life">
						<p class="form_label_p">首次开通时长</p>
						<el-radio-group v-model="ruleForm.useful_life">
							<el-radio :disabled="disabled" label="1">1年</el-radio>
							<el-radio :disabled="disabled" label="2">2年</el-radio>
							<el-radio :disabled="disabled" label="3">3年</el-radio>
							<el-radio :disabled="disabled" label="5">5年</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="" prop="status">
						<p class="form_label_p">状态</p>
						<el-radio-group v-model="ruleForm.status">
							<el-radio :disabled="disabled" label="1">激活</el-radio>
							<el-radio :disabled="disabled" label="0">冻结</el-radio>
						</el-radio-group>
					</el-form-item>
					<div  class="el-form-item_last"></div>
					<el-form-item class="el-form-item-preservation">
						<div class="page_edit_preservation">
							<p class="p1" @click="upPage">返回</p>
							<p class="p2" v-if="modifyBtn" @click="createForm('ruleForm')">保存</p>
							<p class="p2" v-else @click="editForm('ruleForm')">保存</p>
						</div>
					</el-form-item>
				</el-form>
			</div>
	
		</div>
		
		
	
	</div>
</template>

<script>
	
	export default {
		name: 'agentEdit',
		data() {
			return {
				ruleForm: {
					name: '',
					mobile: '',  // 手机号
					contactor: '', // 联系人
					agent_role_id: '',  // 代理商角色id
					useful_life: '1',  // 有效期限
					status: '1',  // 状态
					sort: '',  // 排序
				},
				rules: {
					name: [{
						required: true,
						message: '名称不能为空',
						trigger: 'blur'
					}],
					mobile: [{
							required: true,
							message: '请填写手机号',
							trigger: 'blur'
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: '手机号错误'
						}
					],
					contactor: [{
						required: true,
						message: '联系人不能为空',
						trigger: 'blur'
					}],
					agent_role_id: [{
						required: true,
						message: '请选择代理商',
						trigger: 'change'
					}],
					sort: [
						{
							required: true,
							message: '请填写排序',
							trigger: 'blur'
						},
						{
						pattern: /^\+?[1-9][0-9]*$/,
						message: '只能是非0的正整数',
					}],
				},
				modifyBtn: true, // 默认创建
				roleLidt: [],  // 代理商角色
				disabled: false, // 默认可修改
			}
		},
		components: {
			
		},
		created() {
			if (this.$route.query.id) {
				this.modifyBtn = false;
				
				// 获取代理商详情做修改
				this.getAgentInfo()
			}
			
			// 获取代理商角色
			this.getRoleList();
			
		},
		methods: {
			
			// 获取代理商角色
			getRoleList(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}agentrole/agentrolelist`,
					data: {}
				}).then( res => {
					if(res.code == '0'){
						this.roleLidt = res.data;
					}
				})
			},
			
			// 获取代理商详情做修改
			getAgentInfo(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}agent/detail`,
					data: {
						id: this.$route.query.id
					}
				}).then( res => {
					if(res.code == '0'){
						this.ruleForm.name = res.data.agent_name;
						this.ruleForm.mobile = res.data.mobile;
						this.ruleForm.contactor = res.data.contactor;
						this.ruleForm.agent_role_id = res.data.agent_role_id;
						this.ruleForm.useful_life = res.data.useful_life;
						this.ruleForm.status = res.data.status;
						this.ruleForm.sort = res.data.sort;
					}
				})
			},

			// 创建
			createForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}agent/add`,
							data: {
								agent_name: this.ruleForm.name,
								mobile: this.ruleForm.mobile,
								contactor: this.ruleForm.contactor,
								agent_role_id: this.ruleForm.agent_role_id,
								useful_life: this.ruleForm.useful_life,
								status: this.ruleForm.status,
								sort: this.ruleForm.sort,
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '创建成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 修改
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}agent/edit`,
							data: {
								id: this.$route.query.id,
								agent_name: this.ruleForm.name,
								mobile: this.ruleForm.mobile,
								contactor: this.ruleForm.contactor,
								agent_role_id: this.ruleForm.agent_role_id,
								useful_life: this.ruleForm.useful_life,
								status: this.ruleForm.status,
								sort: this.ruleForm.sort,
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '修改成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},
			
			// 头部跳转页面
			jumpPage(){
				this.$router.go(-1);
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}

</style>
